import { PrimereactLabelRenderer } from './PrimereactLabelRenderer';

//import { MaterialListWithDetailRenderer } from './MaterialListWithDetailRenderer';

import { PrimereactDividerRenderer } from './PrimereactDividerRenderer';

export const UnwrappedAdditional = {
  PrimereactLabelRenderer,
  //MaterialListWithDetailRenderer,

  PrimereactDividerRenderer
};