import pt from './pt.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';

const translations = {
  pt,
  en,
  es,
  fr
}

export default translations;